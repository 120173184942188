<template>
  <v-card>
    <!-- Chart -->
    <div :key="chartKey">
      <vue-apex-charts
        height="500px"
        :options="mfrStackedChartOptions"
        :series="data[1]"
        type="bar"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'

import { mfrStackedChartOptions } from '@/assets/chartOptions/market'
import { chartService } from '@/api/index'

export default {
  name: 'MfrStackedChart',
  components: {
    VueApexCharts,
  },

  props: {
    height: {
      type: String,
      default: 'auto',
    },
    dateRange: {
      type: Array,
      default: [],
    },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      chartKey: 0,
      mfrStackedChartOptions,
      data: [],
    }
  },
  async mounted() {
    // this.$root.$on('reset-mfr-heat-map-filter-by-mfr', () => {
    //   this.data = []
    //   this.getMongoChartData()
    // })
    this.$root.$on('refresh-charts', () => {
      this.getMongoChartData()
    })

    // this.$root.$on('mfr-heat-map-filter-by-mfr', mfr => {
    //   this.getFilteredData(mfr)
    // })
    await this.getMongoChartData()
  },
  methods: {
    async getFilteredData(mfr) {
      const token = await this.$auth.getTokenSilently()
      this.data = await chartService.getChart(token, 'mfrStackedChart', 'stackedMap', this.dateRange, [mfr], '$in')
    },
    filterCharts(config) {
      console.log(config.w.config.labels[config.dataPointIndex].split(' ')[0])

      //   console.log(config)
    },
    async getMongoChartData() {
      const token = await this.$auth.getTokenSilently()
      this.data = await chartService.getChart(token, 'mfrStackedChart', 'stackedMap', this.dateRange, null, null)
      this.mfrStackedChartOptions.xaxis.categories = this.data[0]

      //   const categories = []
      //   for (let i = 0; i < this.data[0].data.length; i++) {
      //     categories.push(this.data[0].data[i].x)
      //   }

      this.chartKey += 1
    },
  },
}
</script>
