<template>
  <div :key="chartId">
    <vue-apex-charts
      :height="height"
      :options="MfrMarketSharePieChartOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'

import { MfrMarketSharePieChartOptions } from '@/assets/chartOptions/market'
import { chartService } from '@/api/index'

export default {
  name: 'MfrMarketSharePieChart',
  components: {
    VueApexCharts,
  },
  props: {
    height: {
      type: String,
      default: 'auto',
    },
    dateRange: {
      type: Array,
      default: [],
    },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      MfrMarketSharePieChartOptions,
      data: [],
      series: [],
      refresh: false,
      chartId: 0,
    }
  },
  async mounted() {
    this.$root.$on('refresh-charts', () => {
      this.getMongoChartData()
    })
    this.$root.$on('mfr-market-share-pie-chart', config => {
      this.filterCharts(config)
    })
    await this.getMongoChartData()
  },
  methods: {
    async filterCharts(config) {
      if (this.refresh) {
        this.refresh = !this.refresh
        this.$root.$emit('reset-mfr-heat-map-filter-by-mfr')
        await this.getMongoChartData()
      } else {
        // this.MfrMarketSharePieChartOptions.labels = []
        console.log(config)
        const mfr = config.w.config.labels[config.dataPointIndex].split(' ')[0]
        const token = await this.$auth.getTokenSilently()
        this.data = await chartService.getChart(token, 'MfrMarketSharePieChart', null, this.dateRange, [mfr], '$in')

        // added to handle null values
        this.MfrMarketSharePieChartOptions.labels = [mfr]
        this.data = this.data.map(item => item.value)
        this.$root.$emit('mfr-heat-map-filter-by-mfr', mfr)
        this.chartId += 1
        this.refresh = !this.refresh
      }

      //   console.log(config)
    },
    async getMongoChartData() {
      //   this.MfrMarketSharePieChartOptions.labels = []
      const token = await this.$auth.getTokenSilently()
      this.data = await chartService.getChart(token, 'MfrMarketSharePieChart', null, this.dateRange)

      // added to handle null values
      this.MfrMarketSharePieChartOptions.labels = await this.data.map(item => (item.label ? item.label : ''))
      this.series = await this.data.map(item => item.value)
      this.chartId += 1
    },
  },
}
</script>
