<template>
  <div :key="chartKey">
    <v-tabs v-model="currentTab">
      <v-tab
        v-for="item in tabs"
        :key="item.component"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="item in tabs"
        :key="item.component"
      >
        <KeepAlive>
          <component
            :is="tabs[currentTab].component"
            class="tab"
            v-bind="currentProperties"
          ></component>
        </KeepAlive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { ref } from '@vue/composition-api'
import MfrMarketSharePieChart from '@/components/charts/MfrMarketSharePieChart.vue'
import MfrHeatMap from '@/components/charts/MfrHeatMap.vue'
import MfrStackedChart from '@/components/charts/MfrStackedChart.vue'
import { windowAttributes } from '@/util/functions/functionsData'
import { handleResize } from '@/util/functions'
import datePicker from '@/components/filters/datePicker.vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'MarketOverview',
  components: {
    MfrMarketSharePieChart,
    MfrHeatMap,
    datePicker,
    MfrStackedChart,
  },
  setup() {
    const currentTab = ref(0)
    const filterStore = useFilterStore()

    return {
      currentTab,
      filterStore,
    }
  },
  data() {
    return {
      tabs: [
        {
          name: 'Market Share Pie Chart',
          component: 'MfrMarketSharePieChart',

          //   props: { height: this.cardHeight },
        },
        {
          name: 'Market Heat Map',
          component: 'MfrHeatMap',
        },
        {
          name: 'Stacked Chart',
          component: 'MfrStackedChart',
        },
      ],
      chartKey: 0,
      cardHeight: '200',
      fullHeight: 'auto',
      windowAttributes,
      handleResize,
      dateRange: [],
    }
  },
  computed: {
    currentProperties() {
      if (this.tabs[this.currentTab].component === 'MfrMarketSharePieChart') {
        return {
          height: this.cardHeight,
          dateRange: this.filterStore.dateRange,
        }
      }
      if (this.tabs[this.currentTab].component === 'MfrHeatMap') {
        return {
          height: this.fullHeight,
          dateRange: this.filterStore.dateRange,
        }
      }
      if (this.tabs[this.currentTab].component === 'MfrStackedChart') {
        return {
          height: this.cardHeight,
          dateRange: this.filterStore.dateRange,
        }
      }

      return {}
    },
  },
  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)
    this.$root.$off('mfr-market-share-pie-chart')
    this.$root.$off('mfr-heat-map')
    this.$root.$off('refresh-charts')
    this.$root.$off('mfr-heat-map-filter-by-mfr')
    this.$root.$off('reset-mfr-heat-map-filter-by-mfr')
  },
  async mounted() {
    await this.debouncedMatchHeight()
  },
  methods: {
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 150).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
    updateFilter(date) {
      this.dateRange = date

      this.$root.$emit('refresh-charts')
    },
  },
}
</script>
